import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAgentDataAsync } from "./agentThunk"

const initialState = {
    agentData : {},
    
}


//thunk
export const getAgentData = createAsyncThunk('agent/getAgentData', getAgentDataAsync)

const agentSlice = createSlice({
    name : "agent",
    initialState,
    reducers : {
        setAgentData : (state, action) => {
            // delete action.payload.appointment
            state.agentData = action.payload
        }
    },
})


export const { setAgentData } = agentSlice.actions
export default agentSlice.reducer