import React from 'react'
import { useEffect , useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../../../config/params'
import { getCookie, getDeployedTelegramList, getUserData } from '../../../utilities/chatbotMethods'
import {NotificationManager} from 'react-notifications'
import StepLoader from '../../loaders/StepLoader'
import SetupLoader from '../../loaders/SetupLoader'
import { deployTelegramBot, manageTelegramBot } from '../../../utilities/api.services'

function Telegram() {

  const [isLoading , setIsLoading] = useState(true)
  const [telegramToken , setTelegramToken] = useState([])
  const [isInstalled , setIsInstalled] = useState(false)
  const [telegramData , setTelegramData] = useState({})
  const [enable , setEnable] = useState(false)


  useEffect(() => {
    getDeployedTelegramList(localStorage.getItem("botId")).then((res) => {
      if(res.length>0){
        setIsInstalled(true)
        setTelegramToken(res[0].token)
        setTelegramData(res[0])
        setIsLoading(false)
        setEnable(res[0].isActive)
      }else{
        setIsInstalled(false)
        setIsLoading(false)
      }

    })
    
  },[])

  const handleTelegram = async (action) => {
    console.log('first');
    setIsLoading(true); // Start loading
  
    let body = {
      cId: localStorage.getItem("tablealias"),
      agentId: localStorage.getItem("agent_id"),
      botId: localStorage.getItem("botId"),
      userId: telegramData.user_id,
      action: action,
    };
  
    try {
      // Call the manageTelegramBot function
      const response = await manageTelegramBot(body);
      console.log("++++++++ Response ++++++++", response);
  
      let msg;
      if (response.success) {
        if (action === "enable") {
          msg = "Deployment on telegram has been enabled";
          setEnable(true);
        } else if (action === "disable") {
          msg = "Deployment on telegram has been disabled";
          setEnable(false);
        } else {
          setTelegramData({});
          setIsInstalled(false);
          setTelegramToken('');
          msg = "Bot has been disconnected from telegram";
        }
  
        setIsLoading(false); // Stop loading
        NotificationManager.success(msg, '', 2000); // Show success message
      } else {
        setIsLoading(false);
        NotificationManager.error('Error updating bot status', '', 2000); // Show error message
      }
    } catch (err) {
      setIsLoading(false); // Stop loading in case of error
      NotificationManager.error('Error updating bot status', '', 2000); // Show error message
    }
  };
  

  

  const installTelegram = async () => {
    setIsLoading(true); // Start loading
  
    const userData = await getUserData(getCookie("APPYID"));
  
    if (!userData) {
      setIsLoading(false); // Stop loading if user data is not available
      NotificationManager.error('Something went wrong', '', 2000); // Show error message
      return;
    }
  
    console.log('clicked install telegram');
  
    let body = {
      token: telegramToken,
      cid: localStorage.getItem("tablealias"),
      agentId: localStorage.getItem("agent_id"),
      botId: localStorage.getItem("botId"),
      email: userData.email,
    };
  
    try {
      // Call the deployTelegramBot function
      const response = await deployTelegramBot(body);
  
      console.log("++++++++ Response ++++++++", response);
  
      if (response.success) {
        // If successful, update the state and fetch the deployed list
        setIsInstalled(true);
        getDeployedTelegramList(localStorage.getItem("botId")).then((res) => {
          if (res.length > 0) {
            setTelegramData(res[0]);
            setIsInstalled(true);
            setIsLoading(false);
            setEnable(true);
          } else {
            setIsInstalled(false);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false); // Stop loading if the response is not successful
        NotificationManager.error('Invalid bot token', '', 2000); // Show error message
      }
    } catch (error) {
      setIsLoading(false); // Stop loading in case of error
      NotificationManager.error('Error during installation', '', 2000); // Show error message
    }
  };
  

  const toggleTelegramStatus = () => {
    setIsLoading(true)
    if(enable){
      handleTelegram('disable')
      // setEnable(false)
      // setIsLoading(false)
    }else{
      handleTelegram('enable')
      // setEnable(true)
      // setIsLoading(false)
    }
  }
  return (
    <>
    {isLoading?(<SetupLoader/>):
                    (<div className="botWordpress customeWebsiteWidget px-0 py-5">
                      {isInstalled ? <>
                        <div className='flex flex-col bg-[#F4F5F9] border rounded-xl p-4'>
                         
                          <h2 className='text-xl font-semibold mb-4'>{telegramData.first_name?telegramData.first_name:""}</h2>
                          <p>{'https://t.me/' + telegramData.user_name}</p>
                          {/* <input type="button" name="isEnable" id="isEnable" checked={enable} onChange = {(event) => {toggleTelegramStatus(event.target.value)}} /> */}
                         <div className='flex items-center gap-4 mt-5'>
                          <button className=' w-36 bg-[#5371ff] drop-shadow-sm p-2 text-center rounded-3xl font-light text-white whitespace-nowrap hover:drop-shadow-xl' onClick={() => {toggleTelegramStatus()} }>{enable ? 'Disable' : 'Enable'}</button>
                          <button className=' w-36  border border-[#404040] drop-shadow-sm p-2 text-center rounded-3xl font-light text-[#404040] whitespace-nowrap hover:drop-shadow-xl' onClick={() => handleTelegram('uninstall')}>Delete</button>
                         </div>
                        </div>
                      </> : <>
						<div className="widgetParaGraphGuideLine">
							<h3 className="text-sm md:text-xl mb-3">Deploy your bot in your telegram account.</h3>
							<div className='bg-[#F3F5FE] p-4 py-5'>
								<p className="text-sm md:text-base text-[#1D1D1D]">
								Need help?  <a className='text-[#566AC3] hover:underline inline-flex items-center gap-1' href='https://snappy.desk.appypie.com/kb/article/how-to-deploy-your-chatbot-on-telegram' target='_blank'>  How I get the access key <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#566AC3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></a>
								</p>
							</div>
						</div>
						<div className="watchGuideVideo mt-5">
							<label className='md:text-base text-xs text-[#1D1D1D] block mb-3'>Enter the access key (bot token)</label>
							<div className='flex gap-2 items-center flex-wrap md:flex-nowrap w-full'>
								<input value={telegramToken} onChange={(event) => setTelegramToken(event.target.value)} type="text" placeholder='Enter bot token' className="border text-sm text-[#1d1d1d] py-1 p-3 rounded h-10 w-full lg:w-5/6 placeholder:text-[#ABABAB]" />
								<button onClick={() => installTelegram()} disabled={telegramToken.length<1} className={`${telegramToken.length<1 ? 'bg-[#DFE9FF] pointer-events-none border-0' : 'bg-indigo-600'} h-10 border border-indigo-600 text-sm px-3 py-1 text-center rounded   text-white w-full lg:w-1/6 hover:bg-[#5371ff]`}>Enable</button>
							</div>
								
						</div></>}
                      
                    </div>)}
                    </>
  )
}

export default Telegram



