import React, { useState } from 'react'
import {setup} from './../../../server/service'
import { Link } from 'react-router-dom'
import { getCookie, getUserData } from '../../../utilities/chatbotMethods'
import { trackData } from "../../../utilities/api.services"
import { useDispatch } from 'react-redux'
import { setSendWidgetCodePopup } from '../../../features/popup/popupSlice'
import WebsiteSetup from '../../scripts/WebsiteSetup'
import { NotificationManager } from 'react-notifications'
import FormBot from '../../setup/custom-website/FormBot'
import CustomDropdown from './CustomDropdown'

function Website() {

    //local state
    const [depWeb , setDepWeb] = React.useState('customWebsite')
    
    var websiteOptions = setup.website

    //converting options for custom dropdown
    websiteOptions = websiteOptions.map((option) => {
      return {
        ...option,
        title : option.name
      }
    })

    const [displayIconUrl , setDisplayIconUrl] = React.useState(websiteOptions[0].imageUrl)

    const dispatch = useDispatch()

 

    //handler function
    const handleOptionChange = (option) => {
      setDepWeb(option.setupType);
      setDisplayIconUrl(option.imageUrl)
     
	   
    };

	
  
    //handler function for copy text`
 const handleCopyText = async (text) => {

    //LOGGING THE ACTIVITY
    //WHEN USER COPIED THE WIDGET CODE

    const userData = await getUserData(getCookie("APPYID"))
    const activityData = {
      setup : {
        email : userData.email,             
        botType : localStorage.getItem('botType'),
        botId : localStorage.getItem('botId'),
        botTypeName : localStorage.getItem('bot_type'),
        setupType : 'custom website'
          
      }
    }
  
    console.log(activityData , ' rsrftgyhuijkor')
  
  
    trackData('WIDGET_CODE_COPIED' , 'setup' , 'User has copied the widget script' , activityData).then(res => {
      if(res){
        console.log('WIDGET_CODE_COPIED')
      }
    })
    console.log(text ,' kjbhkj')
      copyToClipboard(text)
   }
  
   //getting the text of the script
   const getScript = () => {
    var divText = document.getElementById('myScript').innerText;
    console.log(divText , ' script');
    return divText
   }



    //utility function for copy text
  //copy the given text to clipboard
 const copyToClipboard = (text) => {
  
    navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard: ' + text);
      NotificationManager.success('', 'text copied', 2000)
      // You can provide user feedback here (e.g., display a success message)
    })
    .catch((err) => {
      console.error('Unable to copy text: ', err);
      NotificationManager.success('', 'something went wrong', 2000)
      // Handle errors (e.g., display an error message)
    });
   }
  


    //handler function
      //handler function
      const handleSendWidgetCode = async () => {
        //LOGGING THE ACTIVITY
        //WHEN USER COPIED THE WIDGET CODE


        const userData = await getUserData(getCookie("APPYID"))
        const activityData = {
          setup : {
            email : userData.email,             
            botType : localStorage.getItem('botType'),
            botId : localStorage.getItem('botId'),
            botTypeName : localStorage.getItem('bot_type'),
            setupType : 'custom website'
              
          }
        }

        console.log(activityData , ' dghjdghjksaa')


        trackData('WIDGET_CODE_SEND' , 'setup' , 'User has clicked on the  send the widget code' , activityData).then(res => {
          if(res){
            console.log('WIDGET_CODE_SEND')
          }
        })

        dispatch(setSendWidgetCodePopup(true))
    }

  return (
    <div className='mt-4 pt-4  border-gray-200 border-t'>
        <h3 className='text-[#334763] text-base font-semibold'>Install chat widget on your website</h3>
        <p className='my-4 text-sm text-[#334763]'>Let your visitors contact you via chatbot by adding chat widget to your website.</p>

        <h3 className='text-[#767C87] text-[13px] mb-2'> Select platform</h3>
        {/* <select className='border-gray-200 border w-full p-4 rounded-lg text-sm' name="" id="" value={depWeb} onChange = {(event) => handleOptionChange(event.target.value)}>
        {websiteOptions.map((option) => {
            return <option value={option.setupType}>{option.name}</option>
        })}
        </select> */}

  
        {/* custom dropdown  
         */}
         <CustomDropdown options = {websiteOptions} handleOptionChange = {handleOptionChange} value = {depWeb} imageInCloud = {false} displayIconUrl = {displayIconUrl}/>



                    {depWeb == "customWebsite" ? 
                                <div className="customeWebsite customeWebsiteWidget px-5 py-5">
                                  
                                  
                                  <div className="copyPasteWidget py-5">
                                    <h4 className="text-base font-medium">
                                      Copy and paste the widget code in the &lt;body&gt; section of each page of your website.
                                    </h4>
                                    <p  className="font-medium text-sm text-[#5269C9] text-right mt-3 mobile-code">
                                      <span onClick={() => handleSendWidgetCode()} className="cursor-pointer inline-block underline">Send widget code</span>
                                    </p>
                                    <div className="codeWidget bg-[#F4F5F9] px-5 py-7 flex my-3 relative">
                                      <div className="overflow-x-auto min-w-full max-w-xs w-full">
                                        <p className="text-[#343434] text-sm">
                                        <pre>
                                            <code>
                                          {/* {pre}
                                            */}
                                            <WebsiteSetup/>
                                            </code>
                                        </pre>
                                        </p>
                                      </div>
                                      <span onClick={() => handleCopyText(getScript())} className="flex items-center justify-center border rounded bg-white w-7 h-7 cursor-pointer ml-auto absolute right-2 top-2 mobile-copy">
                                      
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 15.058 17.436"><path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#5269c9"></path></svg>
                                            <span className='md:hidden text-[#5269C9] text-sm font-medium ml-2'>Copy to clipboard</span>
                                      </span>
                                    </div>
                                    <p className="text-sm leading-6 text-[#343434]">
                                      Note: Once you’ve added the code on your website then you need to reload your website to see
                                      the widget code. Widget didn’t appear on your website?{" "}
                                      {/* <Link className="text-sm text-blue-500">Verify installation</Link> */}
                                    </p>
                                  </div>
                                </div>:null }

                    {depWeb == "wordpress" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                            To view step of adding Appy Pie Chatbot account's widget in Wordpress website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-appy-pie-chatbot-s-widget-in-wordpress' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in wordpress
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe
                          className="w-full h-96 my-5"
                          src="https://www.youtube.com/embed/Gfut4qu1lCw"
                          title="How to deploy Appy Pie chatbot's widget in wordpress?"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                    </div>:null }

                    {depWeb == "wix" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Wix website check out the help doc   <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-appy-pie-chatbot-s-widget-in-wix' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in wix
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/IjqzXH8CJmk" title="How to deploy Appy Pie chatbot widget in wix?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "squarespace" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Squarespace website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-sitename-chatbot-s-widget-in-squarespace-website' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in squarespace
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/kgmgzSDgsI8" title="How to deploy Appy Pie chatbot's widget in squarespace website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "shopify" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Shopify website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-appy-pie-chatbot-s-widget-in-shopify' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in shopify
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/Tk8_JfSX5aA" title="How to deploy Appy Pie chatbot's widget in shopify?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "weebly" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Weebly website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-chatbot-s-widget-in-a-website-created-in-weebly' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in weebly
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/7afrwjB3Yds" title="How you can deploy Appy Pie Chatbot’s widget on your Weebly website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "godaddy" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Godaddy website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-appypie-s-chatbot-widget-in-a-website-created-in-godaddy' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in godaddy
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/Tjj6sRmXiqw" title="How you can deploy Appy Pie Chatbot’s widget on your Godaddy website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "magento" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Magento website check out the help doc  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-appypie-s-chatbot-widget-in-a-website-created-in-magento' className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in magento
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/uf96mx5-3Zk" title="How you can deploy Appy Pie Chatbot’s widget on your Magento website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "webflow" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        How to deploy Appy Pie Chatbot's widget in webflow  <Link target="_blank" to='https://www.appypie.com/faqs/how-to-deploy-chatbot-widget-in-webflow-website-builder' className="text-sm text-blue-500">
                        To view step of adding Appy Pie Chatbot account's widget in Webflow website check out the help doc
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/1SGHLFzfn9M" title="How you can deploy Appy Pie Chatbot’s widget on your Webflow website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == 'formbot' ? <FormBot/> : null}
    </div>
  )
}

export default Website