export const utilities = {

    // Returns required cookie
    getCookie: (cname) => {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim(); // Use trim() to remove leading spaces
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  
    // CLEANS THE CROSS SCRIPTING XSS
    cleanupString: (stringItem) => {
      var specialcharacters = stringItem.trim().replace(/[^\w\s]/gi, '')
      return specialcharacters.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
    },
  
    downloadJsonAsCsv: (jsonData, csvFileName) => {
      function convertValue(value) {
        if (typeof value === 'object' && value !== null) {
          return JSON.stringify(value);
        }
        return String(value);
      }
  
      // Convert JSON to CSV
      const csvData = Object.keys(jsonData[0]).join(',') + '\n' +
        jsonData.map(row =>
          Object.values(row).map(convertValue).join(',')
        ).join('\n');
  
      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
  
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvFileName;
  
      // Append the link to the body
      document.body.appendChild(link);
  
      // Trigger a click on the link to start the download
      link.click();
  
      // Remove the link from the body
      document.body.removeChild(link);
    },
  
    //Returns Name in Two UpperCase letters
    nameIntials: (nameData) => {
      var arrayData = nameData.split(" ");
      switch (arrayData.length) {
        case 1:
          return nameData.substr(0, 2).toUpperCase();
  
        default:
          return (
            arrayData[0].charAt(0).toUpperCase() +
            arrayData[arrayData.length - 1].charAt(0).toUpperCase()
          );
      }
    }
  
  };
  
  
  
  export const checkGoogle_Maps_API = async (key) => {
    return new Promise((resolve, reject) => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=${key}`)
        .then(response => {
          if (!response.ok) {
            return resolve(false);
          }
          return response.json();
        })
        .then(data => {
          if (data.results.length) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(error => {
          resolve(false);
        });
    });
  
  }
  