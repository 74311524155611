import AppRoute from "./Template/AppRoute";
import React, { useEffect } from 'react';
const App = () => {
  // useEffect(() => {
  //   if(!window.location.href.includes('chatbotnew') && !window.location.href.includes('chatbotbeta') && !window.location.href.includes('chatbotcopy')  && !window.location.href.includes('stgingchatbot')){

  //     const timer = setTimeout(() => {
  //       const script = document.createElement('script');
  //       script.id = 'appyWidget';
  //       script.src = 'https://chatwidget.software/widget/buildone.js?cid=159523784363947423-159523784481563446';
  //       document.body.appendChild(script);
  //     }, 5000);
  //     return () => clearTimeout(timer); 
  //   }
  // }, []);
  return (
    <AppRoute />
  );
};

export default App;
