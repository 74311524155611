import React , {useState , useEffect} from 'react'
import { getCookie, getDeployedWhatsappList, getUserData,verifyWhatsappBusiness, whatsappLiveChatSetup, whatsappsetup } from '../../../utilities/chatbotMethods'
import { NotificationManager } from 'react-notifications'
import StepLoader from '../../loaders/StepLoader'
import SetupLoader from '../../loaders/SetupLoader'
import { Tooltip as ReactTooltip } from "react-tooltip";

function Whatsapp() {

    
    const [whatsappAccessToken, setWhatsappAccessToken] = useState("")
    const [whatsappAccessId, setWhatsappAccessId] = useState("")
    const [whatsappPhoneId, setWhatsappPhoneId] = useState("")
    const [isLoading , setIsLoading] = useState(true)
    const [isInstalled , setIsInstalled] = useState(false)
    const [enable , setEnable] = useState(false)


    

    const toggleWhatsAppStatus = (event) => {

        if(enable){
            handleWhatsapp("disable")
        }else{
            handleWhatsapp("enable")
        }
    }



    useEffect(() => {
        

        getDeployedWhatsappList(localStorage.getItem('botId')).then((data) => {
            if(data.length > 0){
                setWhatsappAccessToken(data[0].whatsappAccessToken)
                setWhatsappAccessId(data[0].whatsappAccessId)
                setWhatsappPhoneId(data[0].whatsappPhoneId)
                setEnable(data[0].isActive)
                setIsInstalled(true)

            }
            setIsLoading(false)
        })
    },[])


  async function handleWhatsapp(action) {

    console.log(action, ' ikhuhjkhjkh')
    setIsLoading(true)
    const handleDetails = async (action) => {

      const userData = await getUserData(getCookie("APPYID"))

      if (!userData) {
        setIsLoading(false)
        NotificationManager.error('', 'Something went wrong', 2000)
        return
      }

      let whatsappData = {
        alias: localStorage.getItem("tablealias"),
        agentID: localStorage.getItem("agent_id"),
        BOTID: localStorage.getItem("botId"),
        // Token: viberAccessToken,
        whatsappAccessId: whatsappAccessId,
        whatsappAccessToken: whatsappAccessToken,
        whatsappPhoneId: whatsappPhoneId,
        email: userData.email,
        action: action,
        Botname: localStorage.getItem("BOTNAME"),
        // LineUsername: result.basicId,
        // displayName:result.displayName,
        // docId:result.userId,
        Bottype: localStorage.getItem('botType'),
        isActive: action == 'enable' || action == 'setup' ? true : false
      }

      if (localStorage.getItem("botType") == 2) {
        var data = {
          whatsappAccessId: whatsappAccessId,
          whatsappAccessToken: whatsappAccessToken,
          whatsappPhoneId: whatsappPhoneId,
          email: userData.email,

        }

        whatsappLiveChatSetup(data).then(res => {
          whatsappData["liveChatAlias"] = res.owner_id
          whatsappData["liveChatBotId"] = res.botId
          whatsappsetup(whatsappData, action)
            .then((response) => {
              console.log("++++++++ Response ++++++++", response)
              let msg;
              if (response) {
                if (action == "setup") {
                  if (response) {
                    setIsInstalled(true)
                    setIsLoading(false)
                    setEnable(true)
                    NotificationManager.success('Bot Deployed Successfully', '', 2000)

                  } else {
                    setIsLoading(false)
                    NotificationManager.error('', 'Invalid bot token', 2000)
                  }
                }
                else if (action == "enable") {
                  setEnable(true)
                  setIsLoading(false)
                  NotificationManager.success('Bot Enabled Successfully', '', 2000)
                  // setWhatsappList(newList)
                } else if (action == "disable") {
                  setEnable(false)
                  setIsLoading(false)
                  NotificationManager.success('Bot Disabled Successfully', '', 2000)

                } else {
                  setIsLoading(false)
                  setIsInstalled(false)
                  setEnable(false)
                  setWhatsappAccessToken('')
                  setWhatsappAccessId('')
                  setWhatsappPhoneId('')
                  NotificationManager.success('Bot Uninstalled Successfully', '', 2000)
                }
                setIsLoading(false)
                // NotificationManager.success('Bot Deployed Successfully' , '' , 2000)
              } else {
                NotificationManager.error('', 'Error updating bot status', 2000)
              }
            })

        })


      } else {
        console.log("+++++++++ whatsappData +++++++++", whatsappData)
        whatsappsetup(whatsappData, action)
          .then((response) => {
            console.log("++++++++ Response ++++++++", response)
            let msg;
            if (response) {
              if (action == "setup") {
                if (response) {
                  setIsInstalled(true)
                  setIsLoading(false)
                  setEnable(true)
                  NotificationManager.success('Bot Deployed Successfully', '', 2000)

                } else {
                  setIsLoading(false)
                  NotificationManager.error('', 'Invalid bot token', 2000)
                }
              }
              else if (action == "enable") {
                setEnable(true)
                setIsLoading(false)
                NotificationManager.success('Bot Enabled Successfully', '', 2000)
                // setWhatsappList(newList)
              } else if (action == "disable") {
                setEnable(false)
                setIsLoading(false)
                NotificationManager.success('Bot Disabled Successfully', '', 2000)

              } else {
                setIsLoading(false)
                setIsInstalled(false)
                setEnable(false)
                setWhatsappAccessToken('')
                setWhatsappAccessId('')
                setWhatsappPhoneId('')
                NotificationManager.success('Bot Uninstalled Successfully', '', 2000)
              }
              setIsLoading(false)
              // NotificationManager.success('Bot Deployed Successfully' , '' , 2000)
            } else {
              NotificationManager.error('', 'Error updating bot status', 2000)
            }
          })
      }
    }
    if (action === "uninstall") {
      handleDetails(action)
    } else {
      verifyWhatsappBusiness(whatsappAccessId, whatsappPhoneId, whatsappAccessToken)
        .then(result => {
          console.log("Verification Results:", result);
          handleDetails(action)
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Verification failed:", error);
          NotificationManager.error('', 'Invalid details, Please provide correct details.', 3000)
        });
    }
  }



  return (
    <div>
        {isLoading ? <SetupLoader/> :
        
        <>
       
        {isInstalled ? <>
                        <div className='flex flex-col bg-[#F4F5F9] border rounded-xl p-4'>
                         
                          
                          
                          {/* <input type="button" name="isEnable" id="isEnable" checked={enable} onChange = {(event) => {toggleTelegramStatus(event.target.value)}} /> */}
                        
						  <div className='flex items-center gap-4 mt-5'>
							<button className='w-36 bg-[#5371ff] drop-shadow-sm p-2 text-center rounded-3xl font-light text-white whitespace-nowrap hover:drop-shadow-xl' onClick={() => {toggleWhatsAppStatus()} }>{enable ? 'Disable' : 'Enable'}</button>
							<button className='w-36  border drop-shadow-sm p-2 text-center rounded-3xl font-light text-[#fff] whitespace-nowrap hover:drop-shadow-xl bg-red-600 ' onClick={() => handleWhatsapp('uninstall')}>Delete</button>
							</div>
						</div>
                      </> :
        <>
        <h2 className='font-medium text-sm md:text-xl mb-3'>Deploy your bot in whatsapp account</h2>
			<div className='bg-[#F3F5FE] p-4 py-5 my-4'>
				<p className="text-sm md:text-base text-[#1D1D1D]">
				Need help?  <a className='text-[#566AC3] hover:underline inline-flex items-center gap-1' href="https://snappy.desk.appypie.com/portal/en/kb/article/how-to-deploy-chatbot-on-whatsapp" target='_blank'>  Whatsapp Deployment setup guide <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#566AC3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></a>
				</p>
			</div>
        
        <form onSubmit = {() => handleWhatsapp('setup')}>
            <label  className='flex items-center gap-1 w-full text-sm mb-2' htmlFor="">Enter Whatsapp Business number id <svg data-tooltip-id ='gpt-model-number' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#1D1D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></label>
            <input className="px-3 border py-2 text-[#1d1d1d] text-sm font-normal placeholder:text-[#ABABAB] rounded h-10 w-full mb-4" type="text" value={whatsappAccessId} onChange = {(event) => setWhatsappAccessId(event.target.value)} required placeHolder = 'Enter number id' />
            <label className='flex items-center gap-1 w-full text-sm mb-2' htmlFor="">Enter Whatsapp Phone number id <svg data-tooltip-id ='gpt-model-phone' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#1D1D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></label>
            <input className="px-3 border py-2 text-[#1d1d1d] text-sm font-normal placeholder:text-[#ABABAB] rounded h-10 w-full mb-4" type="text" value={whatsappPhoneId} onChange = {(event) => setWhatsappPhoneId(event.target.value)} required placeHolder = 'Enter phone number id'/>
            <label className='flex items-center gap-1 w-full text-sm mb-2' htmlFor="">Enter the access key (bot token) <svg data-tooltip-id ='gpt-model-key' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#1D1D1D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></label>
            <input className="px-3 border py-2 text-[#1d1d1d] text-sm font-normal placeholder:text-[#ABABAB] rounded h-10 w-full mb-4" type="text" value={whatsappAccessToken} onChange = {(event) => setWhatsappAccessToken(event.target.value)} required placeHolder = 'Enter bot token'/>

           <div className='w-full'>
		   	<input disabled={ whatsappAccessId.length<1 || whatsappPhoneId.length<1 || whatsappAccessToken.length<1 }  className={`${whatsappAccessId.length<1 || whatsappPhoneId.length<1 || whatsappAccessToken.length<1  ? 'bg-[#DFE9FF] pointer-events-none border-0' : 'bg-indigo-600 hover:bg-[#5371ff]'} h-10 border border-indigo-600 text-sm px-5 py-1 text-center rounded cursor-pointer text-white w-40`} type="submit" value='Install'/>
		   </div>
		   <ReactTooltip className="z-10" style={{ backgroundColor: "#1d1d1d", color: "#fff", maxWidth:'220px' , fontSize : '12px', textAlign: 'center' }}
            id="gpt-model-number"
            place="top"
            variant="info"
            content="Check the installation guide for generating the Business ID"
          /> 
		  <ReactTooltip className="z-10" style={{ backgroundColor: "#1d1d1d", color: "#fff", maxWidth:'240px' , fontSize : '12px', textAlign: 'center' }}
            id="gpt-model-phone"
            place="top"
            variant="info"
            content="Check the installation guide for generating the Phone number ID"
          /> 
		  <ReactTooltip className="z-10" style={{ backgroundColor: "#1d1d1d", color: "#fff", maxWidth:'220px' , fontSize : '12px', textAlign: 'center' }}
            id="gpt-model-key"
            place="top"
            variant="info"
            content="Check the installation guide for generating the Access token"
          /> 
            
			
        </form>
        </>
        }
        </>}
    </div>
  )
}

export default Whatsapp