import React from 'react'
import { useEffect,useState } from 'react'
import { db } from '../../../config/firebase'
import { API_URL } from '../../../config/params'
import {getAllConnectedFacebookPage, getAllFacebookPage, getCookie, getUserData} from './../../../utilities/chatbotMethods'
import SetupLoader from '../../loaders/SetupLoader'
import { sendFbPagesDetails ,deployMessenger } from "../../../utilities/api.services"
function Facebook() {

  const [isLoading, setIsLoading] = useState(true)
  const [facebookPageList, setFacebookPageList] = useState([])
  const [deployedPages, setDeployedPages] = useState([])

  useEffect(() => {

    (function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");

		window.fbAsyncInit = function () {
			window.FB.init({
				appId: "2455137974712184",
				cookie: true,
				xfbml: true,
				autoLogAppEvents: true,
				version: "v7.0",
			});
		};
	
		getAllFacebookPage().then((res)=>{
      getAllConnectedFacebookPage().then((deployed)=>{
        console.log(deployed,"deployed",res,"all")
        if(deployed.length==0){
          setDeployedPages([])
        }else{
        const arrayOfPageIds = deployed.map(obj => obj.pageId);
        setDeployedPages(arrayOfPageIds)
        }
        setFacebookPageList(res)
        setIsLoading(false)
      })
     

    })
  

  }, [])

  const handleFbLogin = async () => {
  
		window.FB.login(
			 (response) => {
				if (response.status == "connected") {
          setIsLoading(true)
          const messengerData = {
            userId: response.authResponse.userID,
            userToken: response.authResponse.accessToken,
            agentId: localStorage.getItem("agent_id"),
            clientId: localStorage.getItem("tablealias"),
            botId: localStorage.getItem("botId"),
          };
          sendFbPagesDetails(messengerData).then((fbPagesResponse) => {
            console.log("Facebook Pages Response:", fbPagesResponse);

            // Handle the response (e.g., update state or UI)
            getAllFacebookPage().then((allFBPages) => {
              setFacebookPageList(allFBPages);
              setIsLoading(false);
            });
          })
          .catch((error) => {
            console.log("Error during Facebook pages fetch:", error);
            setIsLoading(false);
            NotificationManager.error("Error fetching Facebook Pages", "", 2000);
          });
					// fetch(
					// 	urlfb,
					// 	{
					// 		method: "post",
					// 		body: JSON.stringify(messengerData),
					// 		headers: {
					// 			"Content-Type": "application/json",
					// 		},
					// 	}
					// )
					// 	.then(async (res) => console.log(res))
					// 	.then(async (response) => {
					// 		var allFBPages = await getAllFacebookPage().then(allFBPages => {
               
          //     setFacebookPageList(allFBPages)
					// 		console.log(response, "oppppppopppppoppp");
          //     setIsLoading(false)

          //     })
							
          // })
					// 	.catch((e) => {
					// 		console.log(e, "ERR");
          // });
        }
      },
      {
        scope: "pages_show_list,pages_manage_metadata,pages_messaging",
        return_scopes: true,
      }
    );
  };

  const handleInstall = (pageData) => {
    setIsLoading(true); // Start loading 
    getUserData(getCookie("APPYID"))
      .then((userData) => {
        const messengerData = {
          agentId: localStorage.getItem("agent_id"),
          clientId: localStorage.getItem("tablealias"),
          botId: localStorage.getItem("botId"),
          pageIds: [pageData.pageId],
          email: userData.email,
        };
  
        return deployMessenger(messengerData);
      })
      .then((response) => {
        console.log(response); // Log the response if needed
        // After successful deployment, update the deployed pages
        setDeployedPages([...deployedPages, pageData.pageId]);
      })
      .catch((error) => {
        console.error("Error in handleInstall:", error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading, regardless of success or error
      });
  };
  

  // const handleUninstall = () => {}

  const handleUninstall = (pageData) => {
    setIsLoading(true)
    
      db
        .collection(`user_${localStorage.getItem('tablealias')}`)
        .doc(localStorage.getItem('agent_id'))
        .collection('chatbot')
        .doc(localStorage.getItem('botId'))
        .get()
        .then(doc => {
          console.log(doc.data(),"DOCDATA")
          let fbPagesLinked = doc.data().facebookPagesLinked
          let pageId = pageData.pageId
          if(fbPagesLinked){
          fbPagesLinked.filter((item)=>item.pageId!=pageData.pageId)
          db.collection(`user_${localStorage.getItem('tablealias')}`)
          .doc(localStorage.getItem('agent_id'))
          .collection('chatbot')
          .doc(localStorage.getItem('botId'))
          .update({
            facebookPagesLinked: fbPagesLinked
          })
          // fbPagesLinked.forEach(page => {
            
            db
              .collection('Facebook_Integrations')
              .doc(pageId)
              .update({
                isActive: false
              })
              .then(doc => {
                var newPages=deployedPages.filter((item)=>item!=pageId)
                setDeployedPages(newPages)
                setIsLoading(false)
               
              })
          // })
            }else{
              var newPages=deployedPages.filter((item)=>item!=pageId)
              setDeployedPages(newPages)
              setIsLoading(false)

            }
        })
    
  }
  return (
    <>
    {isLoading ? <SetupLoader/>:
    <>
    <h3 className='text-base lg:text-xl text-black'>Click on the install button to deploy bot on your Facebook page</h3>
      <div className='bg-[#F4F5F9] p-5  md:p-7 rounded mt-5'>
        <p className='text-sm md:text-base text-[#1D1D1D]'>Need help? <a className='text-[#566AC3] hover:underline inline-flex items-center gap-1'  target='_blank' href='https://www.appypie.com/faqs/how-to-deploy-chatbot-s-widget-to-a-facebook-page'>Check the installation guide 
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 13.75V10.1042M10 7H10.0075M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#566AC3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
		</a> </p>
    </div>
    <button onClick={()=>{handleFbLogin()}} className='ml-auto border border-indigo-600 text-sm px-3 py-2 text-center rounded hover:bg-[#5371ff] bg-[#5269C9] text-white my-5'>Connect Facebook </button>

   
    {facebookPageList.length > 0 && facebookPageList.map((page, index) => {
      return (
        <div key={index} className="flex items-center gap-4 justify-between border p-4 rounded-xl mb-4 bg-[#F4F5F9]">
          <div className="col-md-6">
            <p>{page.name}</p>
          </div>
          
          {deployedPages.includes(page.pageId) ? (
          <div className="col-md-6">
            <button
              className=" w-36 bg-[#5873F7]  drop-shadow-sm p-2 text-center rounded-lg font-light text-white whitespace-nowrap hover:drop-shadow-xl"
              onClick={() => {
                handleUninstall(page)
              }}
            >
              Uninstall
            </button>
          </div>) : (
          <div className="col-md-6">
            
     
            <button
              className="w-36 bg-[#5873F7]  drop-shadow-sm p-2 text-center rounded-lg font-light text-white whitespace-nowrap hover:drop-shadow-xl"
              onClick={() => {
                handleInstall(page)
              }}
            >
              Install
            </button>
          </div>
          )}
        </div>
      )
    })}
    </>
    }
    
  </>
  )
}

export default Facebook